import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../shared/ContactForm';

const Contact = () => {
  return (
    <Container
      id='contact-section'
      fluid
      className='contact-section fully mt-5 py-5'
    >
      <Container className='my-5'>
        <Row className='text-left justify-content-between'>
          <Col
            xs={12}
            lg={5}
            className='d-flex flex-column justify-content-center'
          >
            <h4 className='text-semi-bold text-primary mb-3'>Contact</h4>
            <h2 className='text-semi-bold line-height-big'>
              Send us a message and lets get connected!
            </h2>
          </Col>
          <Col xs={12} lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Contact;
