import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import introVideo from 'assets/videos/intro-video.mp4';
import Video from 'components/shared/Video';
import ContactModal from 'components/shared/contactModal/ContactModal';

const HeaderSection = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Row>
        <Col className='text-center'>
          <div className='mx-auto'>
            <h1>
              Professional services
              <br />
              to help
              <br />
              your business
            </h1>

            <p className='mt-4'>
              We make awesome software that helps your business
              <br />
              grow and succeed
            </p>
            <button
              className='dg-button-main dg-text-bold mt-4'
              onClick={() => setShowModal(true)}
            >
              Book consultation
            </button>
          </div>
        </Col>
      </Row>
      <Video className='mt-5' video_url={introVideo} />
      {/* Video by Tony Schnagl from Pexels. https://www.pexels.com/@tony-schnagl?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels  */}
      <ContactModal
        showModal={showModal}
        setShowModal={setShowModal}
        maxScreens={2}
      />
    </div>
  );
};

export default HeaderSection;
