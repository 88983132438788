import Maintenance from 'components/shared/Maintenance';

const Services = () => {
  const is_under_maintenance = true;

  if (is_under_maintenance) {
    return <Maintenance />;
  }

  return (
    <div>
      <h1>Services</h1>
    </div>
  );
};

export default Services;
