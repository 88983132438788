const ModalBanner = (props) => {
  const { currentScreen } = props;

  const ContactScreenContent = () => {
    return (
      <>
        <h2>Lets get some info first</h2>
        <p className='mb-0'>
          Fill out the contact form before moving on to the next screen.
        </p>
      </>
    );
  };

  const AppointmentScreenContent = () => {
    return (
      <>
        <h2>Now lets set a date</h2>
      </>
    );
  };

  return (
    <div className='modal-banner py-3'>
      {currentScreen === 1 ? (
        <ContactScreenContent />
      ) : (
        <AppointmentScreenContent />
      )}
    </div>
  );
};

export default ModalBanner;
