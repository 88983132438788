import { Link } from 'react-router-dom';
import arrowRightCircle from 'assets/icons/arrow-right-circle.svg';
import { Card } from 'react-bootstrap';

const ServiceCard = (props) => {
  const { cardObj } = props;
  return (
    <Card
      className='border-0 p-5 mb-3 d-flex justify-content-between'
      style={{ minHeight: '350px' }}
    >
      <h5>{cardObj.title}</h5>
      <p className='text-size-xs'>{cardObj.description}</p>
      <Link to='/services' className='no-underline'>
        <span
          className='text-primary text-semi-bold'
          style={{ fontSize: '1.25rem' }}
        >
          Read more
          <img
            style={{ paddingLeft: '.35rem', width: '30px', height: '30px' }}
            src={arrowRightCircle}
            alt=''
          />
        </span>
      </Link>
    </Card>
  );
};

export default ServiceCard;
