import { forwardRef } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { Col, Container, Row } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
// import Popover from "@material-ui/core/Popover";
import { Tooltip } from "@material-ui/core";

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const PrettoSlider = withStyles({
  root: {
    color: "#8dbaff",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    color: "#8dbaff",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const VideoControlsWrapper = forwardRef((props, ref) => {
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "video-speed-popover" : undefined;

  const {
    onPlayPause,
    className,
    playing,
    muted,
    onMute,
    onVolumeChange,
    onVolumeSeekUp,
    volume,
    // onPlaybackRateChange,
    // playbackRate,
    onToggleFullScreen,
    played,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    elapsedTime,
    totalDuration,
  } = props;

  return (
    <Container
      fluid
      className={className}
      ref={ref}
      onMouseLeave={(e) => {
        e.target.className.includes("video-controls-wrapper")
          ? (() => {
              setTimeout(() => {
                console.log("test");
                e.target.style.visibility = "hidden";
              }, 3000);
            })()
          : (() => {
              setTimeout(() => {
                console.log("Triggered by: video-controls-wrapper child");
                e.target.parentNode.style.visibility = "hidden";
              }, 3000);
            })();
      }}
    >
      <Container className="mt-4 text-white">
        <div className="video-controls-copy">
          Digital Goose - Let's upgrade your business today!
        </div>
      </Container>
      <Container
        style={{ width: "100%", height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <IconButton className="video-control-item" onClick={onPlayPause}>
          {playing ? (
            <PauseIcon fontSize="large" />
          ) : (
            <PlayArrowIcon fontSize="large" />
          )}
        </IconButton>
      </Container>
      <Container>
        <Row>
          <Col>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              value={played * 100}
              onChange={onSeek}
              onMouseDown={onSeekMouseDown}
              onChangeCommitted={onSeekMouseUp}
              ValueLabelComponent={(props) => (
                <ValueLabelComponent {...props} value={elapsedTime} />
              )}
            />
          </Col>
        </Row>
      </Container>
      <Container className="d-flex align-items-center bottom-controls">
        <IconButton className="video-control-item" onClick={onPlayPause}>
          {playing ? (
            <PauseIcon fontSize="large" />
          ) : (
            <PlayArrowIcon fontSize="large" />
          )}
        </IconButton>
        <IconButton onClick={onMute} className="video-control-item-bottom">
          {muted ? (
            <VolumeOffIcon fontSize="large" />
          ) : (
            <VolumeUpIcon fontSize="large" />
          )}
        </IconButton>
        <VolumeSlider
          min={0}
          max={100}
          defaultValue={volume * 100}
          className="volume-slider"
          onChange={onVolumeChange}
          onChangeCommitted={onVolumeSeekUp}
        />
        <Button className="transparent ml-5" style={{ marginLeft: "1rem" }}>
          {elapsedTime}/{totalDuration}
        </Button>
        <div className="d-flex justify-content-right w-100">
          {/* <IconButton
            onClick={handleClick}
            className="video-control-item-bottom"
          >
            {playbackRate}x
          </IconButton> */}
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Container className="d-flex flex-column-reverse">
              {[0.5, 1, 1.5, 2].map((rate, index) => {
                return (
                  <IconButton
                    key={index + 1}
                    className="video-control-item-bottom"
                    onClick={() => onPlaybackRateChange(rate)}
                  >
                    <span
                      style={{
                        color: rate === playbackRate ? "red" : "secondary",
                      }}
                    >
                      {rate}x
                    </span>
                  </IconButton>
                );
              })}
            </Container>
          </Popover> */}

          <IconButton
            onClick={onToggleFullScreen}
            className="video-control-item"
          >
            <FullScreenIcon fontSize="large" />
          </IconButton>
        </div>
      </Container>
    </Container>
  );
});

export default VideoControlsWrapper;
