import Homepage from 'components/Homepage';
import Blog from 'components/Blog';
import Services from 'components/Services';
import Dashboard from 'components/Dashboard';
import About from 'components/About';
import Contact from 'components/Contact';

export const routes = [
  {
    path: '/',
    component: <Homepage />,
  },
  {
    path: '/blog',
    component: <Blog />,
  },
  {
    path: '/services',
    component: <Services />,
  },
  {
    path: '/about',
    component: <About />,
  },
  {
    path: '/contact',
    component: <Contact />,
  },
];

export const private_routes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
];
