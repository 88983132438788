import { useMutation } from '@apollo/client';
import ADD_CONTACT from '../graphql/mutations/add_contact';

const useContact = (contactData) => {
  const { name, email, phone, message } = contactData;
  const [addContact] = useMutation(ADD_CONTACT, {
    variables: {
      name: name,
      email: email,
      phone: phone,
      message: message,
    },
  });

  return { addContact };
};

export default useContact;
