const Phone = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5584 42.489C18.511 42.4891 18.4637 42.4848 18.417 42.4763C18.3161 42.4579 18.2198 42.4198 18.1336 42.3641C18.0474 42.3085 17.973 42.2364 17.9147 42.152C17.8564 42.0675 17.8152 41.9725 17.7937 41.8722C17.7721 41.7719 17.7705 41.6683 17.7889 41.5674C19.3266 33.1463 15.953 28.215 15.9192 28.1662C15.8295 28.0383 15.7802 27.8865 15.7776 27.7303C15.7751 27.5741 15.8194 27.4208 15.9049 27.29C16.4051 26.5248 16.7676 24.3063 16.7858 21.8949C16.8088 18.9125 16.3221 17.4756 16.0965 17.2779C15.8155 17.086 15.4914 16.9664 15.153 16.9299C14.8146 16.8933 14.4724 16.941 14.1569 17.0686C13.9165 17.163 13.706 17.3205 13.5476 17.5245C13.3892 17.7284 13.2887 17.9713 13.2567 18.2275C13.1047 19.2162 12.6342 20.6172 12.0891 22.2393C11.4879 24.0289 10.8065 26.0576 10.4856 27.7834C10.1198 29.8615 9.94423 31.9687 9.96096 34.0787C9.96577 34.2772 9.89482 34.4701 9.76252 34.6182L6.70139 38.0445C6.63305 38.121 6.5503 38.1834 6.45788 38.2279C6.36546 38.2724 6.26516 38.2984 6.16273 38.3041C6.06029 38.3099 5.95772 38.2955 5.86087 38.2616C5.76402 38.2278 5.67479 38.1752 5.59826 38.1068C5.52174 38.0385 5.45943 37.9557 5.41488 37.8633C5.37034 37.7709 5.34443 37.6706 5.33865 37.5682C5.33286 37.4657 5.34731 37.3632 5.38116 37.2663C5.41502 37.1695 5.46762 37.0802 5.53596 37.0037L8.39436 33.8033C8.39384 31.6892 8.57952 29.5792 8.94924 27.4977C9.29045 25.6639 9.99045 23.5801 10.608 21.7418C11.1082 20.2525 11.5807 18.8461 11.7125 17.9899C11.7859 17.4692 11.9946 16.977 12.3179 16.5623C12.6412 16.1477 13.0677 15.8252 13.5547 15.6272C14.118 15.3968 14.7302 15.3117 15.335 15.3796C15.9398 15.4475 16.5178 15.6663 17.016 16.0158C18.8647 17.351 18.6157 25.0119 17.4571 27.6807C18.292 29.1031 20.7405 34.1019 19.3258 41.8492C19.2928 42.0287 19.198 42.191 19.0578 42.3078C18.9176 42.4247 18.7409 42.4888 18.5584 42.489Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M17.2247 49.5098C17.0715 49.5097 16.9217 49.4646 16.7939 49.38C16.6662 49.2954 16.5661 49.1752 16.5061 49.0342C16.4462 48.8932 16.429 48.7377 16.4568 48.587C16.4845 48.4363 16.556 48.2971 16.6622 48.1867L19.3269 45.4193C19.4167 45.326 19.5283 45.2564 19.6516 45.217C19.775 45.1775 19.9062 45.1694 20.0335 45.1934C20.0626 45.1988 23.0999 45.7371 25.6898 44.4426C28.3874 43.0938 29.7663 42.1027 30.3076 40.1186C30.3637 39.9206 30.4956 39.7527 30.6747 39.6514C30.8539 39.5502 31.0657 39.5237 31.2643 39.5777C31.4629 39.6317 31.6321 39.7619 31.7352 39.94C31.8384 40.118 31.8671 40.3296 31.8152 40.5287C31.1255 43.0568 29.3759 44.3455 26.3886 45.8391C23.915 47.076 21.2072 46.9143 20.1745 46.7906L17.7874 49.2705C17.7146 49.3463 17.6273 49.4066 17.5306 49.4477C17.4339 49.4888 17.3298 49.5099 17.2247 49.5098V49.5098Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.9571 33.7913C38.7544 33.7915 38.5596 33.7128 38.4138 33.572C38.268 33.4311 38.1826 33.2392 38.1757 33.0366C38.1689 32.834 38.241 32.6366 38.3769 32.4862C38.5129 32.3359 38.7019 32.2442 38.9042 32.2306L41.0557 32.0827C41.4374 32.0485 41.7901 31.8648 42.037 31.5717C42.2838 31.2785 42.4048 30.8997 42.3736 30.5177C42.3423 30.1358 42.1613 29.7817 41.8701 29.5326C41.5789 29.2835 41.201 29.1595 40.8188 29.1878L39.4532 29.2958C39.3507 29.3044 39.2475 29.2926 39.1495 29.2612C39.0515 29.2298 38.9607 29.1793 38.8823 29.1127C38.8039 29.0461 38.7395 28.9646 38.6926 28.873C38.6458 28.7814 38.6175 28.6815 38.6094 28.5789C38.6012 28.4764 38.6135 28.3732 38.6453 28.2754C38.6772 28.1776 38.7281 28.087 38.795 28.0089C38.862 27.9308 38.9437 27.8666 39.0355 27.8202C39.1273 27.7738 39.2274 27.7459 39.33 27.7382L40.6956 27.6302C41.4892 27.5735 42.273 27.8328 42.8762 28.3516C43.4794 28.8704 43.8531 29.6066 43.9158 30.3997C43.9786 31.1928 43.7252 31.9786 43.2111 32.5857C42.6969 33.1929 41.9636 33.5722 41.171 33.6409L39.0114 33.7894C38.9932 33.7907 38.9751 33.7913 38.9571 33.7913V33.7913Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.9571 29.2983C38.7544 29.2974 38.5601 29.2178 38.415 29.0763C38.2699 28.9348 38.1855 28.7425 38.1795 28.54C38.1736 28.3374 38.2466 28.1405 38.3831 27.9907C38.5196 27.841 38.7089 27.7501 38.9112 27.7373L40.7106 27.6293C41.0922 27.5941 41.4444 27.4096 41.6905 27.1158C41.9366 26.8221 42.0567 26.443 42.0245 26.0611C41.9923 25.6793 41.8105 25.3256 41.5187 25.0772C41.2269 24.8288 40.8488 24.7058 40.4667 24.735L39.101 24.843C38.8944 24.8593 38.6898 24.7929 38.5322 24.6584C38.3746 24.5239 38.2768 24.3323 38.2605 24.1257C38.2442 23.9191 38.3105 23.7145 38.4451 23.5569C38.5796 23.3992 38.7712 23.3015 38.9778 23.2852L40.3436 23.1774C41.1407 23.1143 41.9301 23.3705 42.5383 23.8896C43.1464 24.4086 43.5235 25.148 43.5865 25.945C43.6495 26.7421 43.3933 27.5315 42.8743 28.1397C42.3552 28.7478 41.6159 29.1249 40.8188 29.1879L39.004 29.2971C38.9887 29.2971 38.9729 29.2983 38.9571 29.2983V29.2983Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M39.0387 24.8453C38.8367 24.8454 38.6425 24.7673 38.4969 24.6273C38.3513 24.4873 38.2655 24.2964 38.2576 24.0945C38.2497 23.8927 38.3203 23.6956 38.4546 23.5447C38.5889 23.3938 38.7764 23.3008 38.9778 23.2852L40.3436 23.1773C40.5337 23.1623 40.719 23.11 40.8889 23.0234C41.0588 22.9368 41.21 22.8175 41.3338 22.6725C41.4576 22.5274 41.5516 22.3594 41.6105 22.178C41.6694 21.9966 41.6919 21.8054 41.6769 21.6153C41.6619 21.4252 41.6096 21.2399 41.523 21.07C41.4363 20.9002 41.3171 20.749 41.172 20.6252C41.027 20.5014 40.859 20.4073 40.6776 20.3485C40.4962 20.2896 40.305 20.267 40.1149 20.282L39.0194 20.3686C38.8134 20.384 38.6096 20.3171 38.4528 20.1827C38.2959 20.0482 38.1987 19.8571 38.1824 19.6511C38.1661 19.4451 38.232 19.2411 38.3658 19.0836C38.4996 18.9262 38.6903 18.8281 38.8962 18.8109L39.9917 18.7242C40.7887 18.6612 41.5782 18.9175 42.1863 19.4365C42.7945 19.9556 43.1715 20.695 43.2345 21.4921C43.2975 22.2892 43.0413 23.0786 42.5222 23.6868C42.0031 24.2949 41.2637 24.672 40.4667 24.735L39.101 24.843C39.0802 24.8439 39.0593 24.8453 39.0387 24.8453Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.9583 18.6341C38.7936 18.6343 38.633 18.5824 38.4995 18.4858C38.366 18.3893 38.2665 18.253 38.2151 18.0964C38.1637 17.9399 38.1632 17.7711 38.2135 17.6142C38.2638 17.4574 38.3624 17.3204 38.4952 17.2229L42.5751 14.2267C42.8686 13.9827 43.0541 13.633 43.0918 13.2532C43.1294 12.8734 43.016 12.4941 42.7761 12.1973C42.5362 11.9005 42.1891 11.7101 41.8099 11.6672C41.4307 11.6243 41.0499 11.7323 40.7497 11.9681L39.474 13.0915C39.3975 13.1626 39.3077 13.2177 39.2096 13.2535C39.1115 13.2893 39.0073 13.3051 38.903 13.2999C38.7988 13.2948 38.6966 13.2688 38.6026 13.2235C38.5085 13.1782 38.4245 13.1145 38.3555 13.0362C38.2865 12.9578 38.2339 12.8665 38.2007 12.7675C38.1676 12.6685 38.1547 12.5638 38.1627 12.4598C38.1707 12.3557 38.1995 12.2543 38.2473 12.1615C38.2952 12.0687 38.3612 11.9865 38.4413 11.9196L39.7304 10.7839C39.7386 10.7765 39.7472 10.7694 39.7558 10.7624C40.3778 10.2599 41.1739 10.0251 41.9691 10.1097C42.3628 10.1515 42.7444 10.2705 43.0921 10.4599C43.4398 10.6492 43.7468 10.9052 43.9956 11.2132C44.2444 11.5212 44.4301 11.8751 44.5421 12.2549C44.6541 12.6347 44.6902 13.0328 44.6484 13.4265C44.6065 13.8202 44.4875 14.2018 44.2982 14.5495C44.1088 14.8972 43.8528 15.2042 43.5449 15.453C43.5357 15.4606 43.5263 15.4679 43.5167 15.4751L39.4208 18.4829C39.2868 18.5814 39.1247 18.6344 38.9583 18.6341V18.6341Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.9578 33.8853C38.7506 33.8853 38.5519 33.803 38.4054 33.6565C38.2589 33.51 38.1766 33.3113 38.1766 33.1041V3.92891C38.1766 2.89375 37.1783 2.05273 35.9512 2.05273H19.5645C18.3371 2.05273 17.3379 2.89434 17.3379 3.92891V16.6478C17.3379 16.855 17.2556 17.0538 17.1091 17.2003C16.9626 17.3468 16.7638 17.4291 16.5566 17.4291C16.3494 17.4291 16.1507 17.3468 16.0042 17.2003C15.8577 17.0538 15.7754 16.855 15.7754 16.6478V3.92891C15.7754 2.03281 17.4746 0.490234 19.5645 0.490234H35.9512C38.0398 0.490234 39.7391 2.0332 39.7391 3.92891V33.1041C39.7391 33.3113 39.6568 33.51 39.5102 33.6565C39.3637 33.803 39.165 33.8853 38.9578 33.8853Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M35.9512 40.9908H19.0625C18.8553 40.9908 18.6566 40.9085 18.5101 40.762C18.3636 40.6155 18.2812 40.4168 18.2812 40.2096C18.2812 40.0024 18.3636 39.8037 18.5101 39.6571C18.6566 39.5106 18.8553 39.4283 19.0625 39.4283H35.9512C37.1783 39.4283 38.1766 38.5863 38.1766 37.5514V32.8486C38.1766 32.6414 38.2589 32.4427 38.4054 32.2962C38.5519 32.1497 38.7506 32.0674 38.9578 32.0674C39.165 32.0674 39.3637 32.1497 39.5102 32.2962C39.6568 32.4427 39.7391 32.6414 39.7391 32.8486V37.5514C39.7391 39.4479 38.0398 40.9908 35.9512 40.9908V40.9908Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.8343 6.26221H16.6138C16.4066 6.26221 16.2079 6.1799 16.0613 6.03338C15.9148 5.88687 15.8325 5.68816 15.8325 5.48096C15.8325 5.27376 15.9148 5.07504 16.0613 4.92853C16.2079 4.78202 16.4066 4.69971 16.6138 4.69971H38.8343C39.0415 4.69971 39.2402 4.78202 39.3867 4.92853C39.5332 5.07504 39.6155 5.27376 39.6155 5.48096C39.6155 5.68816 39.5332 5.88687 39.3867 6.03338C39.2402 6.1799 39.0415 6.26221 38.8343 6.26221Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M38.9579 36.5498H19.2356C19.0284 36.5498 18.8297 36.4675 18.6832 36.321C18.5367 36.1745 18.4543 35.9758 18.4543 35.7686C18.4543 35.5614 18.5367 35.3626 18.6832 35.2161C18.8297 35.0696 19.0284 34.9873 19.2356 34.9873H38.9579C39.1651 34.9873 39.3638 35.0696 39.5103 35.2161C39.6568 35.3626 39.7391 35.5614 39.7391 35.7686C39.7391 35.9758 39.6568 36.1745 39.5103 36.321C39.3638 36.4675 39.1651 36.5498 38.9579 36.5498Z'
        fill='#303030'
        fill-opacity='0.85'
      />
    </svg>
  );
};

export default Phone;
