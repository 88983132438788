import { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import VideoControlsWrapper from 'components/shared/Video/VideoControlsWrapper';
import screenfull from 'screenfull';

const format = (seconds) => {
  if (isNaN(seconds)) {
    return '00:00';
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }

  return `${mm}:${ss}`;
};

let count = 0;

const Video = (props) => {
  const [state, setState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
  });

  const { playing, muted, volume, playbackRate, played, seeking } = state;

  const handleOnPlayPause = () => {
    setState({
      ...state,
      playing: !state.playing,
    });
  };

  const handleMute = () => {
    setState({
      ...state,
      muted: !state.muted,
    });
  };

  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handleVolumeSeekUp = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handleplaybackRateChange = (rate) => {
    setState({
      ...state,
      playbackRate: rate,
    });
  };

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  const handleProgress = (changeState) => {
    if (count > 1) {
      controlsRef.current.style.visibility = 'hidden';
      count = 0;
    }
    if (controlsRef.current.style.visibility === 'visible') {
      count += 1;
    }

    if (!seeking) {
      setState({
        ...state,
        ...changeState,
      });
    }
  };

  const handleSeekChange = (e, newValue) => {
    setState({
      ...state,
      played: parseFloat(newValue / 100),
    });
  };

  const handleSeekMouseDown = (e) => {
    setState({
      ...state,
      seeking: true,
    });
  };

  const handleSeekMouseUp = (e, newValue) => {
    setState({
      ...state,
      seeking: false,
    });
    playerRef.current.seekTo(newValue / 100);
  };

  const handleMouseMove = (e) => {
    controlsRef.current.style.visibility = 'visible';
    count = 0;
  };

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);

  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : '00:00';

  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : '00:00';

  const elapsed = format(currentTime);
  const totalDuration = format(duration);

  return (
    <div className={props.className}>
      <Row>
        <Col>
          <div
            ref={playerContainerRef}
            className='video-mask'
            onMouseMove={() => {
              handleMouseMove();
            }}
          >
            <ReactPlayer
              ref={playerRef}
              className='react-player'
              url={props.video_url}
              playing={playing}
              muted={muted}
              volume={volume}
              playbackRate={playbackRate}
              onProgress={handleProgress}
            />
            <VideoControlsWrapper
              ref={controlsRef}
              className='video-controls-wrapper'
              onPlayPause={handleOnPlayPause}
              playing={playing}
              muted={muted}
              onMute={handleMute}
              onVolumeChange={handleVolumeChange}
              onVolumeSeekUp={handleVolumeSeekUp}
              volume={volume}
              playbackRate={playbackRate}
              onPlaybackRateChange={handleplaybackRateChange}
              onToggleFullScreen={toggleFullScreen}
              played={played}
              onSeek={handleSeekChange}
              onSeekMouseDown={handleSeekMouseDown}
              onSeekMouseUp={handleSeekMouseUp}
              elapsedTime={elapsed}
              totalDuration={totalDuration}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Video;
