const Email = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42.0728 39.1278H7.92721C6.81734 39.1265 5.75329 38.685 4.96852 37.9002C4.18376 37.1154 3.74238 36.0513 3.74121 34.9414V13.8534C3.74238 12.7435 4.18376 11.6794 4.96852 10.8946C5.75329 10.1097 6.81734 9.66826 7.92721 9.66699H42.0728C43.1827 9.66826 44.2467 10.1097 45.0315 10.8946C45.8163 11.6794 46.2577 12.7435 46.2588 13.8534V34.9414C46.2577 36.0513 45.8163 37.1154 45.0315 37.9002C44.2467 38.685 43.1827 39.1265 42.0728 39.1278V39.1278ZM7.92721 11.267C7.24152 11.2677 6.58413 11.5405 6.09931 12.0254C5.61449 12.5103 5.34185 13.1677 5.34121 13.8534V34.9414C5.34185 35.6271 5.61449 36.2845 6.09931 36.7694C6.58413 37.2543 7.24152 37.527 7.92721 37.5278H42.0728C42.7585 37.527 43.4159 37.2543 43.9007 36.7694C44.3855 36.2845 44.6582 35.6271 44.6588 34.9414V13.8534C44.6582 13.1677 44.3855 12.5103 43.9007 12.0254C43.4159 11.5405 42.7585 11.2677 42.0728 11.267H7.92721Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M25 29.1057C24.805 29.1058 24.6167 29.0347 24.4704 28.9057L5.19043 11.8865L6.24923 10.6865L25 27.2401L43.7516 10.6885L44.8104 11.8885L25.5304 28.9077C25.3838 29.0364 25.1951 29.1068 25 29.1057V29.1057Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M4.75537 36.6157L17.7075 21.8582L18.9107 22.9142L5.95857 37.6717L4.75537 36.6157Z'
        fill='#303030'
        fill-opacity='0.85'
      />
      <path
        d='M31.1301 22.8984L32.3333 21.8424L45.2857 36.6003L44.0825 37.6563L31.1301 22.8984Z'
        fill='#303030'
        fill-opacity='0.85'
      />
    </svg>
  );
};

export default Email;
