import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import ModalBanner from '../ModalBanner';
import Calendar from 'react-calendar';
import { useMutation } from '@apollo/client';
import ADD_APPOINTMENT from '../../../graphql/mutations/add_appointment';
import 'react-calendar/dist/Calendar.css';

const AppointmentScreen = (props) => {
  const { modalState, setModalState, setShowModal } = props;
  const {
    appointmentForm: { start, end, confirmed, contactId },
  } = modalState;
  const [addAppointment] = useMutation(ADD_APPOINTMENT, {
    variables: {
      start: start,
      end: end,
      confirmed: confirmed,
      contactId: contactId,
    },
  });

  const handleClickDay = (value) => {
    setModalState({
      ...modalState,
      appointmentForm: {
        ...modalState.appointmentForm,
        start: value.toISOString(),
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('*Appointment submitted*');
    // User needs to select date first in order to register
    addAppointment();
    setShowModal(false);
    setModalState({
      ...modalState,
      contactForm: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      currentScreen: 1,
    });
    // TODO: validate a selection has been made
  };
  return (
    <>
      <Modal.Header className='border-none'>
        <ModalBanner />
      </Modal.Header>
      <Container id='modal-appointment' fluid className='modal-appointment '>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row className='justify-content-center'>
              <Col xs={12} lg={6}>
                <Calendar
                  className='w-100'
                  onClickDay={handleClickDay}
                  calendarType='ISO 8601'
                />
              </Col>
            </Row>
            <Row className='justify-content-center pt-5'>
              <button className='dg-button-main dg-text-bold ' type='submit'>
                Submit
              </button>
            </Row>
          </Form>
        </Container>
      </Container>
    </>
  );
};

export default AppointmentScreen;
