import { Container, Nav as BootstrapNav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo_web from 'assets/images/logo-web.svg';
import logo_small from 'assets/images/logo-small.svg';

const Nav = () => {
  return (
    <Navbar expand='lg' className='my-5'>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>
            <img
              src={logo_web}
              className='d-none d-sm-inline-block align-top'
              alt='Digital Goose - Upgrade your business'
            />
            <img
              src={logo_small}
              className='d-inline-block d-sm-none align-top w-25'
              alt='Digital Goose - Upgrade your business'
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll'>
          <BootstrapNav
            className='ms-auto my-2 my-lg-0 align-items-center'
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <LinkContainer className='mx-lg-1 mx-xl-4' to='/'>
              <BootstrapNav.Link>Home</BootstrapNav.Link>
            </LinkContainer>
            <LinkContainer className='mx-lg-1 mx-xl-4' to='/about'>
              <BootstrapNav.Link>About</BootstrapNav.Link>
            </LinkContainer>
            <LinkContainer className='mx-lg-1 mx-xl-4' to='/services'>
              <BootstrapNav.Link>Services</BootstrapNav.Link>
            </LinkContainer>
            <LinkContainer className='mx-lg-1 mx-xl-4' to='/blog'>
              <BootstrapNav.Link>Blog</BootstrapNav.Link>
            </LinkContainer>
            <LinkContainer className='mx-lg-1 mx-xl-4' to='/contact'>
              <BootstrapNav.Link>Contact</BootstrapNav.Link>
            </LinkContainer>
            <div className='d-flex flex-column align-items-center'>
              <LinkContainer to='/contact'>
                <BootstrapNav.Link className='text-center'>
                  <button className='dg-button-main dg-text-bold'>
                    Connect with us!
                  </button>
                </BootstrapNav.Link>
              </LinkContainer>
              <LinkContainer to='/contact'>
                <BootstrapNav.Link className='text-center'>
                  <span> Let's build something great!</span>
                </BootstrapNav.Link>
              </LinkContainer>
            </div>
          </BootstrapNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;
