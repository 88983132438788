import { Col, Form, Row } from 'react-bootstrap';
import EmailDash from 'components/shared/icons/EmailDash';
import useContact from 'hooks/useContact';
import { useState } from 'react';

const EmailBanner = () => {
  const [emailBannerState, setEmailBannerState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  }); // TODO: find a better way to submit this data

  const { addContact } = useContact(emailBannerState);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    setEmailBannerState({
      ...emailBannerState,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addContact();
    setEmailBannerState({
      email: '',
    });
  };

  return (
    <Row className='align-items-center bg-white px-4 py-4'>
      <Col lg={6} className='d-flex align-items-center mb-xs-4 mb-lg-0'>
        <EmailDash />
        <h4 className='banner-icon'>
          Sign up to receive tips on how to grow your biz!
        </h4>
      </Col>
      <Col lg={6}>
        <Form onSubmit={handleSubmit}>
          <Row className='align-items-center'>
            <Col lg={8}>
              <Form.Group controlId='formBasicText'>
                <Form.Control
                  name='email'
                  type='email'
                  placeholder='Email'
                  onChange={handleChange}
                  value={emailBannerState.email}
                />
              </Form.Group>
            </Col>
            <Col lg={4} className='d-none d-lg-flex'>
              <button className='dg-button-main dg-text-bold' type='submit'>
                Subscribe
              </button>
            </Col>
            <Col xs={12} lg={0} className='d-flex d-lg-none mt-3'>
              <button
                className='dg-button-squared-variant dg-text-bold'
                type='submit'
              >
                Subscribe
              </button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default EmailBanner;
