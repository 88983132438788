import { Col, Row } from 'react-bootstrap';
import arrowRightCircle from 'assets/icons/arrow-right-circle.svg';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className='mt-5' id={'about'}>
      <Row className='align-items-center'>
        <Col md={6}>
          <h4 className='text-semi-bold text-primary mb-3'>About - Snippet</h4>
          <h2 className='text-semi-bold line-height-big'>
            We like helping businesses grow. That's pretty much all there is to
            it
          </h2>
        </Col>
        <Col md={6}>
          <p className='mb-2 text-medium text-light text-size-small line-height-medium'>
            From members in our community to businesses across the country -
            it’s a great feeling when you are able to help others accomplish
            goals in their line of work.
          </p>
          <Link to='/about' className='no-underline'>
            <span
              className='text-primary text-semi-bold'
              style={{ fontSize: '1.25rem' }}
            >
              Read more
              <img
                style={{ paddingLeft: '.35rem', width: '30px', height: '30px' }}
                src={arrowRightCircle}
                alt=''
              />
            </span>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default About;
