import { Container, Row, Col, Card } from 'react-bootstrap';
import arrowLeft from 'assets/icons/arrow-left.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import blogSectionImage1 from 'assets/images/blog-section-image-1.jpg';
import blogSectionImage2 from 'assets/images/blog-section-image-2.jpg';
import blogSectionImage3 from 'assets/images/blog-section-image-3.jpg';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import arrowRightCircle from 'assets/icons/arrow-right-circle.svg';
import 'react-multi-carousel/lib/styles.css';

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  return (
    <div className='carousel-button-group d-flex flex-row justify-content-end'>
      <img
        className='pointer p-0'
        onClick={() => previous()}
        src={arrowLeft}
        style={{ width: '60px', height: '60px' }}
        alt='Left arrow button'
      />
      <img
        className='pointer p-0'
        onClick={() => next()}
        src={arrowRight}
        style={{ width: '60px', height: '60px' }}
        alt='Right arrow button'
      />
    </div>
  );
};

const BlogSection = () => {
  const slider_content = [
    {
      image: blogSectionImage1,
      tag: 'Client relations',
      date: 'January 17, 2022',
      title: 'How we maintain our relationship with our clients',
    },
    {
      image: blogSectionImage2,
      tag: 'Business',
      date: 'January 5, 2022',
      title: '5 habits that will help you grow your business',
    },
    {
      image: blogSectionImage3,
      tag: 'How-to',
      date: 'February 6, 2022',
      title: 'Integrating your social media with your business application',
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 764 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  return (
    <Container fluid className='blog-section pt-5'>
      <Container>
        <Row className='text-left justify-content-between'>
          <Col md={6}>
            <h4 className='text-semi-bold text-primary mb-3'>Blog</h4>
            <h2 className='text-semi-bold line-height-big'>
              Check out what we are up to in our blogs
            </h2>
          </Col>
          <Col md={6} className='d-flex justify-content-end align-items-end'>
            <div className='mt-3'></div>
          </Col>
        </Row>
        <Row className='flex-column-reverse'>
          <Carousel
            autoPlay={false}
            responsive={responsive}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            infinite={true}
          >
            {slider_content.map((slider_item) => {
              return (
                // <Col xs={12} md={12} lg={11}>
                <Card className='border-0 p-5 mb-3 d-flex blog-section-card'>
                  <img src={slider_item.image} alt='' />
                  <p
                    className='text-size-xs pt-2'
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {slider_item.tag} - {slider_item.date}
                  </p>
                  <h5>{slider_item.title}</h5>
                  <Link
                    to='/services'
                    className='no-underline pb-3'
                    style={{ position: 'absolute', bottom: '0' }}
                  >
                    <span
                      className='text-primary text-semi-bold'
                      style={{
                        fontSize: '1.25rem',
                      }}
                    >
                      Read more
                      <img
                        style={{
                          paddingLeft: '.35rem',
                          width: '30px',
                          height: '30px',
                        }}
                        src={arrowRightCircle}
                        alt=''
                      />
                    </span>
                  </Link>
                </Card>
                // </Col>
              );
            })}
          </Carousel>
        </Row>
      </Container>
    </Container>
  );
};

export default BlogSection;
