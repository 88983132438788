import { useState } from 'react';
import useContact from '../../hooks/useContact';
import { Form, Row, Col } from 'react-bootstrap';

const ContactForm = ({ whiteBackground }) => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const { addContact } = useContact(formState);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addContact();
    setFormState({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className={whiteBackground ? 'bg-white px-4 py-4' : ''}
    >
      <Row>
        <Col xs={12}>
          <Form.Group className='mb-4' controlId='formBasicText'>
            <Form.Control
              className='dg-input'
              onChange={handleChange}
              name='name'
              value={formState.name}
              type='text'
              placeholder='Enter your name'
            />
            <Form.Text className='text-muted'></Form.Text>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className='mb-4' controlId='formBasicPassword'>
            <Form.Control
              className='dg-input'
              onChange={handleChange}
              name='phone'
              value={formState.phone}
              type='text'
              placeholder='Enter your phone'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group className='mb-4' controlId='formBasicPassword'>
            <Form.Control
              className='dg-input'
              onChange={handleChange}
              name='email'
              value={formState.email}
              type='email'
              placeholder='Enter your email'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col xs={12}>
          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
            <Form.Control
              className='dg-textarea'
              onChange={handleChange}
              name='message'
              value={formState.message}
              as='textarea'
              rows={3}
              placeholder='Tell us about your business'
            />
          </Form.Group>
        </Col>
      </Row>
      <button className='dg-button-squared-variant dg-text-bold' type='submit'>
        Send message
      </button>
    </Form>
  );
};

export default ContactForm;
