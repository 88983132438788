import { Row } from 'react-bootstrap';
// import { IconButton } from '@material-ui/core';
// import { Facebook, Twitter, Instagram } from '@material-ui/icons';

const PublicFooter = () => {
  return (
    <div class='public-footer pt-2 mt-5'>
      {/* <Row className='text-center'>
      TODO: Move sm icons to contact
        <p className='mt-4 mb-0'>Add our social media!</p>
        <div>
          <IconButton className='video-control-item'>
            <Instagram fontSize='large' />
          </IconButton>
          <IconButton className='video-control-item'>
            <Facebook fontSize='large' />
          </IconButton>
          <IconButton className='video-control-item'>
            <Twitter fontSize='large' />
          </IconButton>
        </div>
      </Row> */}
      <Row className='text-center mt-5'>
        <span className='text-light'>
          ©2022 Digital Goose. All Rights Reserved. |
          johnathan.guzman@digitalgoose.io | (312) 586 0047
        </span>
      </Row>
    </div>
  );
};

const Footer = () => {
  return <PublicFooter />;
};

export default Footer;
