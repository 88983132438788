const EmailDash = () => {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 75 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z'
        fill='#8DBAFF'
      />
      <path
        d='M50.9703 28.5278C50.5904 28.145 49.9722 28.1425 49.5893 28.5223C49.2064 28.9021 49.2039 29.5205 49.5838 29.9034L49.5896 29.9095C49.7798 30.1012 50.029 30.1967 50.2785 30.1967C50.5273 30.1967 50.7766 30.1016 50.9678 29.9119C51.3507 29.532 51.3502 28.9107 50.9703 28.5278Z'
        fill='white'
      />
      <path
        d='M62.714 40.2716L53.6438 31.2015C53.2625 30.8202 52.6441 30.8202 52.2627 31.2015C51.8813 31.5828 51.8813 32.2012 52.2627 32.5826L59.4631 39.7829H42.2927C40.8683 39.7829 39.7096 38.6241 39.7096 37.1998V20.0295L46.661 26.9809C47.0424 27.3621 47.6607 27.3621 48.0422 26.9809C48.4235 26.5995 48.4235 25.9812 48.0422 25.5997L39.2209 16.7785C38.8396 16.3973 38.2212 16.3973 37.8398 16.7785L22.2715 32.3472C21.8901 32.7285 21.8901 33.3469 22.2715 33.7283L45.7646 57.2213C45.9477 57.4044 46.1961 57.5073 46.4551 57.5073C46.7141 57.5073 46.9625 57.4044 47.1456 57.2213L62.7141 41.6527C62.8972 41.4695 63.0001 41.2212 63.0001 40.9622C63 40.7032 62.8971 40.4548 62.714 40.2716ZM37.7566 19.6241V32.0612H25.3195L37.7566 19.6241ZM45.4785 54.173L25.3195 34.0143H37.7566V37.1998C37.7566 39.7011 39.7916 41.736 42.2929 41.736H45.4785V54.173ZM47.4315 54.1731V41.736H59.8686L47.4315 54.1731Z'
        fill='white'
      />
      <path
        d='M19.7022 36.8046H13.9766C13.4372 36.8046 13 37.2419 13 37.7812C13 38.3204 13.4372 38.7577 13.9766 38.7577H19.7022C20.2416 38.7577 20.6788 38.3205 20.6788 37.7812C20.6788 37.2419 20.2416 36.8046 19.7022 36.8046Z'
        fill='white'
      />
      <path
        d='M22.6139 36.8046H22.5992C22.0599 36.8046 21.6227 37.2419 21.6227 37.7812C21.6227 38.3204 22.0599 38.7577 22.5992 38.7577H22.6139C23.1532 38.7577 23.5904 38.3204 23.5904 37.7812C23.5904 37.2419 23.1532 36.8046 22.6139 36.8046Z'
        fill='white'
      />
      <path
        d='M17.1031 26.551H13.9778C13.4385 26.551 13.0013 26.9883 13.0013 27.5275C13.0013 28.0668 13.4385 28.5041 13.9778 28.5041H17.1031C17.6425 28.5041 18.0797 28.0668 18.0797 27.5275C18.0797 26.9883 17.6425 26.551 17.1031 26.551Z'
        fill='white'
      />
      <path
        d='M23.9873 26.551H20.2932C19.7538 26.551 19.3166 26.9883 19.3166 27.5275C19.3166 28.0668 19.7538 28.5041 20.2932 28.5041H23.9873C24.5267 28.5041 24.9639 28.0668 24.9639 27.5275C24.9639 26.9883 24.5267 26.551 23.9873 26.551Z'
        fill='white'
      />
      <path
        d='M26.1135 42.1756H20.6448C20.1055 42.1756 19.6683 42.6129 19.6683 43.1521C19.6683 43.6914 20.1055 44.1287 20.6448 44.1287H26.1135C26.6528 44.1287 27.09 43.6914 27.09 43.1521C27.09 42.6129 26.6527 42.1756 26.1135 42.1756Z'
        fill='white'
      />
      <path
        d='M30.3836 48.4254H16.6142C16.0748 48.4254 15.6376 48.8627 15.6376 49.402C15.6376 49.9412 16.0748 50.3785 16.6142 50.3785H30.3836C30.9229 50.3785 31.3602 49.9412 31.3602 49.402C31.3602 48.8627 30.9229 48.4254 30.3836 48.4254Z'
        fill='white'
      />
    </svg>
  );
};

export default EmailDash;
