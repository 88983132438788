import { Row, Col } from 'react-bootstrap';
import ServiceCards from 'components/shared/ServiceCards';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div className='mt-5' id='services'>
      <Row className='justify-content-center text-center'>
        <Col md={6}>
          <h4 className='text-semi-bold text-primary mb-3'>Services</h4>
          <h2 className='text-semi-bold line-height-big'>
            Some of the things we help with
          </h2>
        </Col>
      </Row>
      <ServiceCards />
      <Row className='text-center mt-5'>
        <Link to='/services' className='no-underline'>
          <span
            className='text-primary text-semi-bold border-bottom-primary'
            style={{ fontSize: '1.25rem' }}
          >
            See full list of services
          </span>
        </Link>
      </Row>
    </div>
  );
};

export default Services;
