import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes, private_routes } from 'config/routes';
import Nav from './shared/Nav';
import { Container } from 'react-bootstrap';
import Footer from 'components/shared/Footer';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Container>
          <Nav />
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              );
            })}

            {private_routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component} // private route ex. <PrivateRoute>{route.component}</PrivateRoute>
                />
              );
            })}
          </Routes>
          <Footer />
        </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;
