import { gql } from '@apollo/client';

const ADD_CONTACT = gql`
  mutation addContact(
    $name: String!
    $email: String!
    $phone: String!
    $message: String!
  ) {
    addContact(
      input: {
        params: { name: $name, email: $email, phone: $phone, message: $message }
      }
    ) {
      contact {
        id
        name
        email
        phone
        message
      }
    }
  }
`;

export default ADD_CONTACT;
