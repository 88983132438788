import { useEffect } from 'react';
import About from 'components/Homepage/About';
import BlogSection from 'components/Homepage/BlogSection';
import Contact from 'components/Homepage/Contact';
import Feedback from 'components/Homepage/Feedback';
import HeaderSection from 'components/Homepage/HeaderSection';
import Services from 'components/Homepage/Services';

const Homepage = () => {
  useEffect(() => {
    document.querySelector('nav').style.display = 'flex'; // TODO: remove this - don't need
  }, []);
  return (
    <div id='homepage'>
      <HeaderSection />
      <About />
      <Services />
      <Feedback />
      <Contact />
      <BlogSection />
    </div>
  );
};

export default Homepage;
