import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AppointmentScreen from './AppointmentScreen';
import ContactScreen from './ContactScreen';
import Circle from '../Circle';
import { useMutation } from '@apollo/client';
import ADD_CONTACT from '../../../graphql/mutations/add_contact';

const ContactModal = (props) => {
  const end = new Date();
  const [modalState, setModalState] = useState({
    contactForm: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    appointmentForm: {
      start: '',
      end: end.toISOString(), // TODO: have fix for this
      confirmed: false,
      contactId: null,
    },
    currentScreen: 1,
    maxScreens: props.maxScreens,
  });

  const {
    contactForm,
    contactForm: { name, email, phone, message },
    currentScreen,
    maxScreens,
  } = modalState;

  const [addContact] = useMutation(ADD_CONTACT, {
    variables: {
      name: name,
      email: email,
      phone: phone,
      message: message,
    },
    onCompleted: (data) => {
      const contact_id = parseInt(data.addContact.contact.id);
      setModalState({
        ...modalState,
        appointmentForm: {
          ...modalState.appointmentForm,
          contactId: contact_id,
        },
        currentScreen: maxScreens === 2 && 2,
      });
    },
  });

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    setModalState({
      ...modalState,
      contactForm: {
        ...modalState.contactForm,
        [name]: value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addContact();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body className='modal-body'>
        {currentScreen === 1 ? (
          <ContactScreen
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            contactFormState={contactForm}
            currentScreen={currentScreen}
            maxScreens={maxScreens}
            setShowModal={props.setShowModal}
          />
        ) : (
          <AppointmentScreen
            modalState={modalState}
            setModalState={setModalState}
            setShowModal={props.setShowModal}
          />
        )}
      </Modal.Body>
      <div className='d-flex justify-content-center my-3'>
        <Circle
          className={
            maxScreens === 2 && currentScreen === 2 ? 'opacity-35' : ''
          }
        />
        {maxScreens === 2 && (
          <Circle
            className={
              maxScreens === 2 && currentScreen === 1 ? 'opacity-35' : ''
            }
          />
        )}
      </div>
    </Modal>
  );
};

export default ContactModal;
