import { gql } from '@apollo/client';

const ADD_APPOINTMENT = gql`
  mutation addAppointment(
    $start: ISO8601DateTime!
    $end: ISO8601DateTime!
    $confirmed: Boolean!
    $contactId: Int!
  ) {
    addAppointment(
      input: {
        params: {
          start: $start
          end: $end
          confirmed: $confirmed
          contactId: $contactId
        }
      }
    ) {
      appointment {
        id
        start
        end
        confirmed
        contactId
      }
    }
  }
`;

export default ADD_APPOINTMENT;
