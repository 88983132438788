import { useRef, useState } from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import sliderImage1 from 'assets/images/slider-image-1.jpg';
import sliderImage2 from 'assets/images/slider-image-2.jpg';
import sliderImage3 from 'assets/images/slider-image-3.jpg';
import arrowLeft from 'assets/icons/arrow-left.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import quotes from 'assets/images/quotes.svg';

const Feedback = () => {
  const slider_content = [
    {
      image: sliderImage1,
      user_feedback:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sodales neque sodales ut etiam sit amet nisl purus in. Quis ipsum suspendisse ultrices gravida.',
      author: 'Sofia Hernandez',
      sub: 'Owner of A&B Company',
    },
    {
      image: sliderImage2,
      user_feedback:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Congue quisque egestas diam in arcu cursus euismod quis.',
      author: 'Yūri Crawford',
      sub: 'Owner of Overseas Shipping Co.',
    },
    {
      image: sliderImage3,
      user_feedback:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quisque egestas diam in arcu cursus euismod quis viverra nibh. Risus in hendrerit gravida rutrum quisque non tellus.',
      author: 'Ronnette Sansone',
      sub: "Owner of San's Pet Care",
    },
  ];

  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);

  const ref = useRef();

  const onPrevClick = () => {
    ref.current.prev();
  };

  const onNextClick = () => {
    ref.current.next();
  };

  return (
    <div className='mt-5 mb-3'>
      <Row className='text-left'>
        <Col md={6}>
          <h4 className='text-semi-bold text-primary mb-3'>
            Some feedback from others
          </h4>
          <h2 className='text-semi-bold line-height-big'>
            How our help has impacted other businessess
          </h2>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} lg={6}>
          <Carousel
            variant='dark'
            controls={false}
            indicators={false}
            onSlide={(ek) => setCurrentSliderIndex(ek)}
            ref={ref}
          >
            {slider_content.map((slider_item) => {
              return (
                <Carousel.Item>
                  <img
                    className='d-block w-100 border-radius-small height-350'
                    src={slider_item.image}
                    alt={slider_item.sub}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div className='mt-3'>
            <Row className='justify-content-center'>
              <img
                src={arrowLeft}
                style={{ width: '60px', height: '60px' }}
                className='pointer p-0'
                alt='Left arrow button'
                onClick={() => {
                  onPrevClick();
                }}
              />
              <img
                src={arrowRight}
                style={{ width: '60px', height: '60px' }}
                className='pointer p-0'
                alt='Right arrow button'
                onClick={() => {
                  onNextClick();
                }}
              />
            </Row>
          </div>
        </Col>
        <Col xs={12} lg={6} className='mt-4 height-350'>
          <img src={quotes} alt='' />
          <p className='mt-3'>
            {slider_content[currentSliderIndex].user_feedback}
          </p>
          <div className='d-flex flex-column'>
            <span>
              <b>{slider_content[currentSliderIndex].author}</b>
            </span>
            <span>{slider_content[currentSliderIndex].sub}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Feedback;
