import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from 'components/shared/ContactForm';
import Maintenance from 'components/shared/Maintenance';
import PhoneIcon from 'components/shared/icons/Phone';
import EmailIcon from 'components/shared/icons/Email';
import EmailBanner from 'components/shared/Banners/EmailBanner';

const Contact = () => {
  const is_under_maintenance = false;

  if (is_under_maintenance) {
    return <Maintenance />;
  }
  return (
    <Container>
      <Row className='justify-content-between mb-5'>
        <Col xs={11} lg={5}>
          <Row>
            <h4 className='text-semi-bold text-primary mb-3'>Contact</h4>
            <h2 className='text-semi-bold line-height-big mb-3'>
              Send us a message and lets get connected!
            </h2>
            <p>
              Fill the form or choose another way to reach us
              <span>&nbsp;📲</span>
            </p>
          </Row>
          <Row className='my-4'>
            <a href='tel:+3125860047' className='text-decoration-none'>
              <Col className='d-flex align-items-center mb-lg-4'>
                <PhoneIcon />
                <p className='mb-1 contact-link-text'>312-586-0047</p>
              </Col>
            </a>
            <a
              href='mailto:johnathan.guzman@digitalgoose.io'
              className='text-decoration-none'
            >
              <Col className='d-flex align-items-center'>
                <EmailIcon />
                <p className='mb-1 contact-link-text'>
                  johnathan.guzman@digitalgoose.io
                </p>
              </Col>
            </a>
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <ContactForm whiteBackground={true} />
        </Col>
      </Row>
      <Row className='pt-5 justify-content-center'>
        <Col xs={11}>
          <EmailBanner />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
