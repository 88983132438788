import { Modal, Container, Form, Row, Col } from 'react-bootstrap';
import ModalBanner from '../ModalBanner';

const ContactScreen = (props) => {
  const {
    handleSubmit,
    handleChange,
    contactFormState,
    currentScreen,
    maxScreens,
  } = props;
  return (
    <>
      <Modal.Header className='border-none'>
        <ModalBanner currentScreen={currentScreen} />
      </Modal.Header>
      <Container id='modal-contact' fluid className='contact-section '>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row className='justify-content-center pt-3'>
              <Col xs={12} lg={6}>
                <Form.Group controlId='formBasicText'>
                  <Form.Label>Your name</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name='name'
                    value={contactFormState.name}
                    type='text'
                    placeholder='Enter your name'
                  />
                  <Form.Text className='text-muted'></Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-center pt-4'>
              <Col xs={12} lg={6}>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name='email'
                    value={contactFormState.email}
                    type='email'
                    placeholder='Enter your email'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-center pt-4'>
              <Col xs={12} lg={6}>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name='phone'
                    value={contactFormState.phone}
                    type='text'
                    placeholder='Enter your phone'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-center pt-5'>
              <button className='dg-button-main dg-text-bold ' type='submit'>
                {maxScreens === 1 ? 'Submit' : 'Continue'}
              </button>
            </Row>
          </Form>
        </Container>
      </Container>
    </>
  );
};

export default ContactScreen;
