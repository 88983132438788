import Maintenance from 'components/shared/Maintenance';

const Blog = () => {
  const is_under_maintenance = true;

  if (is_under_maintenance) {
    return <Maintenance />;
  }
  return (
    <div>
      <h1>Blog</h1>
    </div>
  );
};

export default Blog;
