import { useEffect } from 'react';
import astronaut from 'assets/images/astronaut.png';
import { Container } from 'react-bootstrap';

const Maintenance = () => {
  useEffect(() => {
    document.querySelector('nav').style.display = 'none';
  }, []);
  return (
    <Container className='d-flex flex-column maintenance justify-content-evenly align-items-center'>
      <h1 className='text-x-bold text-center w-75'>
        Sorry, but this part is under maintenance. Come back soon!
      </h1>
      <img src={astronaut} alt='Astronaut' className='w-25' />
    </Container>
  );
};

export default Maintenance;
