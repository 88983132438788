import { Col, Row } from "react-bootstrap";
import ServiceCard from "./ServiceCard";

const service_cards_content = [
  {
    title: "Business development",
    description:
      "The bottom line is that we help make your busines better. We like to make sure we have the right strategy in place in order to get the best results.",
  },
  {
    title: "Website and mobile app development",
    description:
      "From online stores, blogs, subcription services to enterprise applications and tools. We do a little bit of everything.",
  },
  {
    title: "Digital marketing and online presence",
    description:
      "One of our favorite things we like to do here at Digital Goose is establishing an online presence and setting you up for success.",
  },
];

const ServiceCards = () => {
  return (
    <Row className="mt-5">
      {service_cards_content.map((cardItem) => {
        return (
          <Col xs={12} md={6} lg={4}>
            <ServiceCard cardObj={cardItem} />
          </Col>
        );
      })}
    </Row>
  );
};

export default ServiceCards;
